import React, { useEffect, useRef } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import TableRowItem from "../utils/TableRowItem";
import { useGlobalState } from "../../GlobalStateContext";

const SeaweedAssumptions = () => {
  let seaweedFarmingBox = useRef();

  const { state, dispatch } = useGlobalState();

  seaweedFarmingBox.current = HomeBox(
    "/assets/seaweed.jpg",
    "Seaweed Farming",
    "Seaweeds are macro algae, generally occurring in marine habitats. They are multi-cellular constructions that can be seen with the naked eye, but are different from the higher plants since they do not possess true roots, stems, or leaves, making them thalloid in nature. They are beautiful and colorful and it is not an exaggeration to call them ocean ornamentals. "
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Seaweed Farming</h2>
              {seaweedFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>Assumptions</strong>

              <br />
              <br />

              <table className="table is-fullwidth" style={{ width: "70%" }}>
                <tr>
                  <th>Item</th>
                  <th>Value</th>
                </tr>
                <TableRowItem item="Seaweed growing period (days)" value="42" />
                <TableRowItem item="Seaweed growing period (weeks)" value="6" />

                <TableRowItem
                  item="Size of the seaweed farm (m2)"
                  value="2000"
                  updateFunction="SET_SEAWEED_FARM_SIZE"
                />
                <TableRowItem item="Seaweed cuttings weight (kg)" value="700" />
                <TableRowItem
                  item="Plastic straws (rolls) of tie-tie"
                  value="14"
                />
                <TableRowItem item="Staking man-days labor" value="10" />
                <TableRowItem item="Miscellaneous (10%)" value="0.1" />

                <TableRowItem
                  item="Farm production management labor"
                  value="21"
                />
                <TableRowItem
                  item="Depreciation of equipment (KES) – 4 years lifespan"
                  value="5000"
                />
                <TableRowItem item="Number of crops per year" value="5" />
                <TableRowItem
                  item="Cost of man-day (5 hours) - KES"
                  value="300"
                />
                <TableRowItem
                  item="Price of 1 roll of plastic straws (tie-tie)"
                  value="350"
                />

                <TableRowItem item="Price of 1 kg of dry seaweed" value="35" />

                <TableRowItem item="Price of cuttings per Kg" value="10" />
              </table>
              <br />

              <br />
              <br />
              {/* <img src="/assets/dashboard.png" alt="Dashboard" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeaweedAssumptions;

import React, { useEffect, useRef, useState } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import RowItemHeader from "../utils/RowItemHeader";
import RowItem from "../utils/RowItem";
import items from "./items.json";
import { useGlobalState } from "../../GlobalStateContext";

const Documentation = () => {
  const [totalGrossReceipts, setTotalGrossReceipts] = useState(0.0);
  const [totalVariableCosts, setTotalVariableCosts] = useState(0.0);
  const [totalCosts, setTotalCosts] = useState(0.0);
  const [totalDepreciationCosts, setTotalDepreciationCosts] = useState(0.0);
  const { state, dispatch } = useGlobalState();

  let seaweedFarmingBox = useRef();

  seaweedFarmingBox.current = HomeBox(
    "/assets/seaweed.jpg",
    "Seaweed Farming",
    "Seaweeds are macro algae, generally occurring in marine habitats. They are multi-cellular constructions that can be seen with the naked eye, but are different from the higher plants since they do not possess true roots, stems, or leaves, making them thalloid in nature. They are beautiful and colorful and it is not an exaggeration to call them ocean ornamentals. "
  );

  const grossReceipts = [];
  const variableCosts = [];
  const depreciationCosts = [];

  items.forEach((item, index) => {
    let itemTotal = item.unitCost * item.qty;
    item.total = itemTotal;

    if (item.category === "gross_receipts") {
      grossReceipts.push(item);
    }

    if (item.category === "variable_costs") {
      variableCosts.push(item);
    }

    if (item.category === "depreciation") {
      depreciationCosts.push(item);
    }
  });

  useEffect(() => {
    // window.scrollTo(0, 0);

    calculateTotalGrossReceipts();
    calculateTotalVariableCosts();
    calculateTotalDepreciationCosts();
  }, []);

  function calculateTotalGrossReceipts() {
    let total = 0.0;
    grossReceipts.forEach((item, index) => {
      total += item.total;
    });

    const formattedNumber = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalGrossReceipts(formattedNumber);
    console.log(totalGrossReceipts);
    console.log(totalDepreciationCosts);
  }

  function calculateTotalVariableCosts() {
    let total = 0.0;
    variableCosts.forEach((item, index) => {
      total += item.total;
    });

    setTotalVariableCosts(total);

    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);

    let totalCosts = totalDepreciationCosts + total;

    const formattedNumber = totalCosts.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalCosts(formattedNumber);
  }

  function calculateTotalDepreciationCosts() {
    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);

    let totalCosts = totalDepreciationCosts + totalVariableCosts;

    const formattedNumber = totalCosts.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalCosts(formattedNumber);
  }

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Seaweed Farming</h2>
              {seaweedFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>
                Business Model Costs Calculator
              </strong>
              <p className="">
                Update the open fields. Comprehensive financial statements, and
                reports will be generated automatically. These statements offer
                a comprehensive perspective on the financial well-being of the
                project.
                <br /> <br />
                <div
                  className="buttons"
                  style={{ marginTop: "2px", marginBottom: "25px" }}
                >
                  <a
                    href="https://docs.google.com/spreadsheets/d/1WU-dE0mVzJ_SGt_QUTQb8ZBlKGXq47Ob63dKlaRg_jc/edit#gid=0"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                  >
                    <strong>Google Excel Sheet</strong>
                  </a>
                  &nbsp;
                  <a
                    href="/assets/GIZ-Business-Model-Costs-Calculator.xlsx"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                    download
                  >
                    <strong>Download Excel</strong>
                  </a>
                </div>
                <hr style={{ borderTop: "1px solid #bdbdbd" }} />
                <br />
                <RowItemHeader
                  item1="Item"
                  item2="Unit Cost (KES)"
                  item3="Quantity"
                  item4="Total Cost (KES)"
                />
                <RowItemHeader
                  item1="Gross Receipts"
                  item2=""
                  item3=""
                  item4=""
                />
                {grossReceipts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Variable Costs"
                  item2=""
                  item3=""
                  item4=""
                />
                {variableCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Variable Costs"
                  item2=""
                  item3=""
                  item4={totalVariableCosts.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
                <RowItemHeader
                  item1="Fixed Costs (non-cash expenses)"
                  item2=""
                  item3=""
                  item4=""
                />
                {depreciationCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Fixed Costs"
                  item2=""
                  item3=""
                  item4="5,000.00"
                />
                <RowItemHeader
                  item1="Total Costs"
                  item2=""
                  item3=""
                  item4={totalCosts}
                />
                <RowItemHeader
                  item1="Net income per crop"
                  item2=""
                  item3=""
                  item4="37,720.00"
                />
                {/* <div className="field is-horizontal">
                  <div className="field-label"> 
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <button className="button is-primary">Calculate</button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </p>
              <br />

              <br />
              <br />
              {/* <img src="/assets/dashboard.png" alt="Dashboard" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Documentation;

import React from "react";
// import { Link } from "react-router-dom";

const HomeBox = (imageURL, title, description) => {
  return (
    <>
      <div className="box">
        <article className="media" style={{}}>
          <div className="media-left">
            <figure className="image is-128x128">
              <img src={imageURL} alt={title} />
            </figure>
          </div>
          <div className="media-content">
            <div className="content">
              <p>
                <strong>{title}</strong>{" "}
                {/* <small>@johnsmith</small> <small>31m</small> */}
                <br />
                {description}
              </p>
            </div>
            {/* <nav className="level is-mobile">
              <div className="level-left">
                <div className="level-item" aria-label="reply">
                  <span className="icon is-small">
                    <i className="fas fa-reply" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="level-item" aria-label="retweet">
                  <span className="icon is-small">
                    <i className="fas fa-retweet" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="level-item" aria-label="like">
                  <span className="icon is-small">
                    <i className="fas fa-heart" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </nav> */}
          </div>
        </article>
      </div>
    </>
  );
};

export default HomeBox;

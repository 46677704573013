import React, { useEffect, useRef } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import TableRowItem from "../utils/TableRowItem";

const MudCrabAssumptions = () => {
  let mudCrabFarmingBox = useRef();

  mudCrabFarmingBox.current = HomeBox(
    "/assets/mudcrab.jpg",
    "Mud Crab Farming",
    "Mud crabs belong to the family Portunidae and the genus Scylla. There are four species of mud crabs i.e. Scylla serrata, Scylla tranquebarica, Scylla olivacea and Scylla paramamosain."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Mud Crab Farming</h2>
              {mudCrabFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>
                Assumptions of Mud Crab Fattening Business in Floating Cages
              </strong>

              <br />
              <br />

              <table className="table is-fullwidth" style={{ width: "70%" }}>
                <tr>
                  <th>Item</th>
                  <th>Value</th>
                </tr>
                <TableRowItem item="Crab seed average weight (g)" value="250" />
                <TableRowItem
                  item="Harvest weight after fattening (g)"
                  value="500"
                />

                <TableRowItem
                  item="Price of market size adult crab per kg (KES)"
                  value="1000"
                />

                <TableRowItem
                  item="Cost of crab seed per kg (KES)"
                  value="150"
                />

                <TableRowItem
                  item="Weight of crab seed per cage (kg)"
                  value="1"
                />

                <TableRowItem
                  item="Cost of transporting seed (KES) for stocking one cage"
                  value="200"
                />

                <TableRowItem item="Fattening period (months)" value="4" />
                <TableRowItem item="Number of crops per year" value="3" />
                <TableRowItem item="Survival rate per cycle (%)" value="65" />

                <TableRowItem
                  item="Number of compartments in a cage"
                  value="10"
                />

                <TableRowItem
                  item="Number of cages used in production"
                  value="30"
                />

                <TableRowItem
                  item="Number of crablets needed for stocking"
                  value="300"
                />

                <TableRowItem
                  item="Cost of cage construction per cage (KES)"
                  value="1500"
                />

                <TableRowItem
                  item="Crab production per cycle per cage (kg)"
                  value="4"
                />

                <TableRowItem
                  item="Feeding rate (% body weight per day)"
                  value="10"
                />
              </table>
              <br />

              <br />

              <strong style={{ marginBottom: "5px" }}>
                Assumptions for Mud Crab Rearing in Grow-Out Ponds
              </strong>

              <br />
              <br />

              <table className="table is-fullwidth" style={{ width: "70%" }}>
                <tr>
                  <th>Item</th>
                  <th style={{ textAlign: "center" }}>Value</th>
                </tr>
                <TableRowItem item="Number of 200 m2 ponds used" value="5" />
                <TableRowItem
                  item="Size of crablet (juvenile) at stocking (g)"
                  value="20"
                />

                <TableRowItem item="Price of crablet (KES)" value="10" />
                <TableRowItem
                  item="Size of growing ponds (m&sup2;)"
                  value="200"
                />
                <TableRowItem
                  item="Stocking density (number/m&sup2;)"
                  value="2"
                />

                <TableRowItem
                  item="Growth period (production cycle) – months"
                  value="6"
                />

                <TableRowItem item="Growth period (days)" value="180" />

                <TableRowItem
                  item="Average growth rate per crab per day (g/day)"
                  value="2"
                />

                <TableRowItem item="Survival rate in the pond (%)" value="60" />

                <TableRowItem item="Size of crab at harvest (g)" value="400" />

                <TableRowItem
                  item="Price of crab per kg at harvest (KES)"
                  value="1000"
                />

                <TableRowItem
                  item="Cost of construction one 200m&sup2; pond (KES)"
                  value="30000"
                />

                <TableRowItem
                  item="Lifespan of the pond with regular maintenance (years)"
                  value="15"
                />

                <TableRowItem
                  item="Feeding rate (% body weight per day)"
                  value="10"
                />

                <TableRowItem
                  item="Number of crablets needed per cycle"
                  value="2000"
                />

                <TableRowItem
                  item="Labor required for farm management per day (man-day)"
                  value="0.5"
                />
              </table>
              <br />

              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MudCrabAssumptions;

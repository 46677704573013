import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";

const Documentation = () => {
  let aiBox = useRef();
  let seaweedFarmingBox = useRef();
  let mudCrabFarmingBox = useRef();
  let prawnFarmingBox = useRef();
  let milkfishFarmingBox = useRef();
  let prawnAndMilkfishFarmingBox = useRef();
  let tilapiaFarmingBox = useRef();

  aiBox.current = HomeBox(
    "/assets/ai.jpg",
    "AI Chatbot",
    "AI Chatbot trained on Agriprofit. Empowering Decision-Making in Agriculture Ventures. Your Comprehensive Solution for Accurate Financial Projections and Informed Agribusiness Choices."
  );

  seaweedFarmingBox.current = HomeBox(
    "/assets/seaweed.jpg",
    "Seaweed Farming",
    "Seaweeds are macro algae, generally occurring in marine habitats."
  );

  mudCrabFarmingBox.current = HomeBox(
    "/assets/mudcrab.jpg",
    "Mud Crab Farming",
    "Mud crabs belong to the family Portunidae and the genus Scylla. "
  );

  prawnFarmingBox.current = HomeBox(
    "/assets/prawns.jpg",
    "Prawn Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable prawn farming."
  );

  milkfishFarmingBox.current = HomeBox(
    "/assets/milkfish.jpg",
    "Milkfish Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable milkfish farming."
  );

  prawnAndMilkfishFarmingBox.current = HomeBox(
    "/assets/prawns.jpg",
    "Prawn & Milkfish Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable prawn and milkfish farming."
  );

  tilapiaFarmingBox.current = HomeBox(
    "/assets/tilapia.jpg",
    "Tilapia Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable tilapia farming."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Getting Started</h2>
              <p className="">
                Welcome to the Mariculture App! Getting started is easy and
                here's a step-by-step guide to help you make the most of our
                user-friendly platform. <br />
                <br />
                <div className="ml-6">
                  <ol type="1">
                    <li>
                      Visit the Respective Assumptions Tab for your desired
                      business
                    </li>
                    <li>
                      Fill in biological, operational, market, and economic
                      variables
                    </li>
                    <li>
                      Click on the "Calculate" button to get your results.
                    </li>
                  </ol>
                </div>
                <br />
                The following modules are available; <br />
                <br />
                {/* <h1 className="title">
              AgriProfit: Empowering Decision-Making in Agriculture Ventures
            </h1>
            <h2 className="subtitle">
              Your Comprehensive Solution for Accurate Financial Projections and
              Informed Agribusiness Choices.
            </h2> */}
                <div className="columns">
                  <div className="column">
                    <Link to={{ pathname: `/seaweed` }}>
                      {seaweedFarmingBox.current}
                    </Link>
                  </div>
                  <div className="column">
                    <Link to={{ pathname: `/mud-crab` }}>
                      {mudCrabFarmingBox.current}
                    </Link>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Link to={{ pathname: `/prawn` }}>
                      {prawnFarmingBox.current}
                    </Link>
                  </div>
                  <div className="column">
                    <Link to={{ pathname: `/milkfish` }}>
                      {milkfishFarmingBox.current}
                    </Link>
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <Link to={{ pathname: `/tilapia` }}>
                      {tilapiaFarmingBox.current}
                    </Link>
                  </div>
                  <div className="column">
                    <Link to={{ pathname: `/agriprofit-ai` }}>
                      {aiBox.current}
                    </Link>
                  </div>
                </div>
                <br />
              </p>
              <br />

              <br />
              <br />
              {/* <img src="/assets/dashboard.png" alt="Dashboard" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Documentation;

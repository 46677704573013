import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import SignUp from "./pages/sign_up";

// Admin dashboard routes
import About from "./pages/about";
import AgriprofitAI from "./pages/agriprofit-ai";
import Configurations from "./pages/admin/configurations";
import Contact from "./pages/contact";
import Documentation from "./pages/documentation/index";
import MilkfishHome from "./pages/milkfish/index";
import MilkfishAssumptions from "./pages/milkfish/assumptions";
import MudCrabHome from "./pages/mud-crab/index";
import MudCrabAssumptions from "./pages/mud-crab/assumptions";
import MudCrabFivePondsCalculator from "./pages/mud-crab/five-ponds-calculator";
import PrawnHome from "./pages/prawn/index";
import PrawnAssumptions from "./pages/prawn/assumptions";
import PrawnMilkfishHome from "./pages/prawn-milkfish/index";
import PrawnMilkfishAssumptions from "./pages/prawn-milkfish/assumptions";
import Profile from "./pages/admin/profile";
import SeaweedHome from "./pages/seaweed/index";
import SeaweedAssumptions from "./pages/seaweed/assumptions";
import StrutsAI from "./pages/struts_ai";
import SupportEngine from "./SupportEngine";
import TilapiaHome from "./pages/tilapia/index";
import TilapiaAssumptions from "./pages/tilapia/assumptions";
import QRCode from "./pages/qr-code";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} />
        <Route path="/agriprofit-ai" element={<AgriprofitAI />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/dashboard" element={<Home />} exact />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/configurations" element={<Configurations />} />
        <Route path="/documentation" element={<Documentation />} />
        <Route path="/prawn" element={<PrawnHome />} />
        <Route path="/prawn/assumptions" element={<PrawnAssumptions />} />
        <Route path="/prawn-milkfish" element={<PrawnMilkfishHome />} />
        <Route
          path="/prawn-milkfish/assumptions"
          element={<PrawnMilkfishAssumptions />}
        />
        <Route path="/milkfish" element={<MilkfishHome />} />
        <Route path="/milkfish/assumptions" element={<MilkfishAssumptions />} />
        <Route path="/mud-crab" element={<MudCrabHome />} />
        <Route path="/mud-crab/assumptions" element={<MudCrabAssumptions />} />
        <Route
          path="/mud-crab/five-ponds"
          element={<MudCrabFivePondsCalculator />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/seaweed" element={<SeaweedHome />} />
        <Route path="/seaweed/assumptions" element={<SeaweedAssumptions />} />
        <Route path="/struts-ai" element={<StrutsAI />} />
        <Route path="/tilapia" element={<TilapiaHome />} />
        <Route path="/tilapia/assumptions" element={<TilapiaAssumptions />} />
        <Route path="/qr-code" element={<QRCode />} />
      </Routes>

      <SupportEngine />
    </>
  );
};

export default App;

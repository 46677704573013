import React from "react";

function RowItem({ item1, item2, item3, item4 }) {
  return (
    <>
      <div className="columns" style={{ borderBottom: "1px solid #e6e6e6" }}>
        <div className="column is-4 has-text-right0">
          <b>{item1}</b>
        </div>
        <div className="column is-2">
          <b>{item2}</b>
        </div>
        <div className="column is-2">
          <b>{item3}</b>
        </div>
        <div className="column is-2 has-text-right">
          <b>{item4}</b>
        </div>
      </div>
    </>
  );
}

export default RowItem;

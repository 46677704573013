import React, { useEffect, useRef, useState } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import RowItemHeader from "../utils/RowItemHeader";
import RowItem from "../utils/RowItem";
import items from "./items.json";

const Documentation = () => {
  const [totalFixedCosts, setTotalFixedCosts] = useState(0.0);
  const [totalGrossReceipts, setTotalGrossReceipts] = useState(0.0);
  const [totalVariableCosts, setTotalVariableCosts] = useState(0.0);
  const [totalCosts, setTotalCosts] = useState(0.0);
  const [totalDepreciationCosts, setTotalDepreciationCosts] = useState(0.0);

  const [salePricePerKg, setSalePricePerKg] = useState(300);
  const [totalNumCycles, setTotalNumCycles] = useState(20);
  const [totalSalesQty, setTotalSalesQty] = useState(900);

  let prawnAndMilkfishFarmingBox = useRef();

  prawnAndMilkfishFarmingBox.current = HomeBox(
    "/assets/milkfish.jpg",
    "Milkfish Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable prawn and milkfish farming."
  );

  const fixedCosts = [];
  const grossReceipts = [];
  const variableCosts = [];
  const depreciationCosts = [];

  let totalSales = {
    name: "Total Sales",
    unitCost: salePricePerKg,
    qt: totalSalesQty,
  };

  loadCosts();

  useEffect(() => {
    window.scrollTo(0, 0);

    calculateTotalFixedCosts();
    calculateTotalGrossReceipts();
    calculateTotalVariableCosts();
    calculateTotalDepreciationCosts();
    getTotalCosts();
  }, []);

  function loadCosts() {
    items.forEach((item, index) => {
      let itemTotal = item.unitCost * item.qty;
      item.total = itemTotal;

      if (item.category === "fixed_costs") {
        fixedCosts.push(item);
      }

      if (item.category === "gross_receipts") {
        grossReceipts.push(item);
      }

      if (item.category === "variable_costs") {
        variableCosts.push(item);
      }

      if (item.category === "depreciation") {
        depreciationCosts.push(item);
      }
    });
  }

  function updateNumCycles(numCycles) {
    setTotalNumCycles(numCycles);

    loadCosts();
    // calculateTotalFixedCosts();
    getTotalCosts();
  }

  function calculateTotalFixedCosts() {
    let total = 0.0;
    fixedCosts.forEach((item, index) => {
      total += item.total;
    });

    total = total / totalNumCycles;

    const formattedNumber = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalFixedCosts(formattedNumber);
    getTotalCosts();
  }

  function calculateTotalGrossReceipts() {
    let total = 0.0;
    grossReceipts.forEach((item, index) => {
      total += item.total;
    });

    const formattedNumber = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalGrossReceipts(formattedNumber);
    console.log(totalGrossReceipts);
    console.log(totalDepreciationCosts);
  }

  function calculateTotalVariableCosts() {
    let total = 0.0;
    variableCosts.forEach((item, index) => {
      total += item.total;
    });

    setTotalVariableCosts(total);

    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);
    getTotalCosts();
  }

  function calculateTotalDepreciationCosts() {
    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);
    getTotalCosts();
  }

  function getTotalCosts() {
    let total = 0.0;

    // Fixed costs
    fixedCosts.forEach((item, index) => {
      total += item.total;
    });

    total = total / totalNumCycles;

    variableCosts.forEach((item, index) => {
      total += item.total;
    });

    depreciationCosts.forEach((item, index) => {
      total += item.total;
    });

    const formattedNumber = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalCosts(formattedNumber);
  }

  function calculateTotalSales() {}

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Milkfish Farming</h2>
              {prawnAndMilkfishFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>
                Business Model Costs Calculator
              </strong>
              <p className="">
                Update the open fields. Comprehensive financial statements, and
                reports will be generated automatically. These statements offer
                a comprehensive perspective on the financial well-being of the
                project.
                <br />
                <br />
                <div
                  className="buttons"
                  style={{ marginTop: "2px", marginBottom: "25px" }}
                >
                  <a
                    href="https://docs.google.com/spreadsheets/d/1DLxvmd3rR2euvk96nvaHzUS8mjVsuAoW/edit#gid=324635755"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                  >
                    <strong>Google Excel Sheet</strong>
                  </a>
                  &nbsp;
                  <a
                    href="/assets/Milkfish-Enterprise-budget.xlsx"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                    download
                  >
                    <strong>Download Excel</strong>
                  </a>
                </div>
                <hr style={{ borderTop: "1px solid #bdbdbd" }} />
                <strong style={{ marginBottom: "5px" }}>Assumptions</strong>
                <br />
                <br />
                <table className="table is-fullwidth">
                  <tr>
                    <th>Item</th>
                    <th>Value</th>
                  </tr>
                  <tr>
                    <td>Sale price per Kg</td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        className="input"
                        type="text"
                        value={salePricePerKg}
                        onChange={(e) => setSalePricePerKg(e.target.value)}
                        style={{ height: "75%" }}
                      />
                      {/* {value} */}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Total number of cycles in a 10 year period (2 Cycles per
                      year)
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <input
                        className="input"
                        type="text"
                        value={totalNumCycles}
                        onChange={(e) => updateNumCycles(e.target.value)}
                        style={{ height: "75%" }}
                      />
                      {/* {value} */}
                    </td>
                  </tr>
                </table>
                {/* <hr style={{ borderTop: "1px solid #bdbdbd" }} /> */}
                <br />
                <strong style={{ marginBottom: "15px" }}>
                  Enterprise Budget for 600 m2 Prwans Farm
                </strong>
                <br />
                <br />
                <RowItemHeader
                  item1="Item"
                  item2="Unit Cost (KES)"
                  item3="Quantity"
                  item4="Total Cost (KES)"
                />
                <RowItemHeader
                  item1="Gross Receipts"
                  item2=""
                  item3=""
                  item4=""
                />
                {grossReceipts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                {/* Start of Total Sales  */}
                {/* {RowItem(
                  totalSales,
                  calculateTotalGrossReceipts,
                  calculateTotalVariableCosts
                )} */}
                {/* End of Total sales */}
                {/* { {grossReceipts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )} */}
                <RowItemHeader item1="Fixed Costs" item2="" item3="" item4="" />
                {fixedCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalFixedCosts,
                    calculateTotalVariableCosts,
                    totalNumCycles
                  )
                )}
                <RowItemHeader
                  item1="Total Fixed Costs"
                  item2=""
                  item3=""
                  item4={totalFixedCosts.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
                <RowItemHeader
                  item1="Variable Costs"
                  item2=""
                  item3=""
                  item4=""
                />
                {variableCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Variable Costs"
                  item2=""
                  item3=""
                  item4={totalVariableCosts.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
                <RowItemHeader
                  item1="Fixed Costs (non-cash expenses)"
                  item2=""
                  item3=""
                  item4=""
                />
                {depreciationCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Fixed Costs"
                  item2=""
                  item3=""
                  item4="5,000.00"
                />
                <RowItemHeader
                  item1="Total Costs"
                  item2=""
                  item3=""
                  item4={totalCosts}
                />
                <RowItemHeader
                  item1="Net income per crop"
                  item2=""
                  item3=""
                  item4="37,720.00"
                />
              </p>
              <br />

              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Documentation;

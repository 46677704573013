import React, { useEffect, useRef } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import TableRowItem from "../utils/TableRowItem";

const TilapiaAssumptions = () => {
  let tilapiaFarmingBox = useRef();

  tilapiaFarmingBox.current = HomeBox(
    "/assets/tilapia.jpg",
    "Tilapia Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable tilapia farming."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Tilapia Farming</h2>
              {tilapiaFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>Assumptions</strong>

              <br />
              <br />

              <table className="table is-fullwidth" style={{ width: "70%" }}>
                <tr>
                  <th>Item</th>
                  <th>Value</th>
                </tr>
                <TableRowItem item="Standard pond size (m2)" value="300" />
                <TableRowItem item="Number of ponds required" value="3" />
                <TableRowItem
                  item="Cost of constructing a 300 m2 pond (KES)"
                  value="40,000"
                />
                <TableRowItem
                  item="Pond life span with regular repairs (years)"
                  value="15"
                />
                <TableRowItem item="Stocking density (fish/m2)" value="2" />
                <TableRowItem
                  item="Production cycle length (months)"
                  value="9"
                />
                <TableRowItem
                  item="Production cycle length (weeks)"
                  value="36"
                />
                <TableRowItem
                  item="Production cycle length (days)"
                  value="270"
                />
                <TableRowItem
                  item="Growth rate of fish (gram/fish/day)"
                  value="0.75"
                />
                <TableRowItem
                  item="Size of male tilapia fingerling (g)"
                  value="5"
                />
                <TableRowItem
                  item=" Price of male tilapia fingerling (KES)"
                  value="10"
                />
                <TableRowItem
                  item="Size of tilapia at harvest (g)"
                  value="208"
                />
                <TableRowItem
                  item="Price of tilapia at harvest/piece (KES)"
                  value="120"
                />
                <TableRowItem
                  item="Survival rate in the pond (%)"
                  value="0.856"
                />
                <TableRowItem
                  item="Fertilization rate (g/m2/week) DAP"
                  value="2"
                />
                <TableRowItem
                  item="Fertilization rate (g/m2/week) UREA"
                  value="3"
                />
                <TableRowItem item="Price of DAP/Kg (KES)" value="120" />
                <TableRowItem item="Price of UREA/Kg (KES)" value="110" />
                <TableRowItem
                  item="Price of manual labor (man-day) (KES)"
                  value="500"
                />
                <TableRowItem
                  item="Labour needed for day to day pond mgt. (Man-day)"
                  value="0.5"
                />
                <TableRowItem item="Lime application rate (g/m2)" value="200" />
                <TableRowItem item="Price of lime/kg (KES)" value="50" />
                <TableRowItem
                  item="Value of essential assorted aquaculture equipment (KES)"
                  value="30,900"
                />
                <TableRowItem item="Lifespan of equipment (years)" value="3" />
              </table>
              <br />

              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TilapiaAssumptions;

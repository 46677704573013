import React from "react";
import { Link } from "react-router-dom";

const DocumentationSideMenu = () => {
  return (
    <>
      <aside className="menu" style={{ padding: "10px" }}>
        <p className="menu-label">Getting Started</p>
        <ul className="menu-list">
          <li>
            <Link to="/documentation">Instructions</Link>
          </li>
          <li>
            <Link to="/qr-code">QR Code</Link>
          </li>
          <li>
            <Link to="/agriprofit-ai">Agriprofit AI</Link>
          </li>
        </ul>
        <p className="menu-label">Seaweed Farming</p>
        <ul className="menu-list">
          <li>
            <Link to="/seaweed/assumptions">Assumptions</Link>
          </li>
          <li>
            <Link to="/seaweed">Calculator</Link>
          </li>
        </ul>
        <p className="menu-label">Mud Crab Farming</p>
        <ul className="menu-list">
          <li>
            <Link to="/mud-crab/assumptions">Assumptions</Link>
          </li>
          <li>
            <Link to="/mud-crab">Calculator 30-Cage</Link>
          </li>
          <li>
            <Link to="/mud-crab/five-ponds">Calculator 5-Ponds</Link>
          </li>
        </ul>

        <p className="menu-label">Prawn Farming</p>
        <ul className="menu-list">
          <li>
            <Link to="/prawn/assumptions">Assumptions</Link>
          </li>
          <li>
            <Link to="/prawn">Calculator</Link>
          </li>
        </ul>

        <p className="menu-label">Milkfish Farming</p>
        <ul className="menu-list">
          <li>
            <Link to="/milkfish/assumptions">Assumptions</Link>
          </li>
          <li>
            <Link to="/milkfish">Calculator</Link>
          </li>
        </ul>

        <p className="menu-label">Tilapia Farming</p>
        <ul className="menu-list">
          <li>
            <Link to="/tilapia/assumptions">Assumptions</Link>
          </li>
          <li>
            <Link to="/tilapia">Calculator</Link>
          </li>
        </ul>
      </aside>
    </>
  );
};

export default DocumentationSideMenu;

import React, { useEffect, useRef } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import TableRowItem from "../utils/TableRowItem";

const SeaweedAssumptions = () => {
  let prawnAndMilkfishFarmingBox = useRef();

  prawnAndMilkfishFarmingBox.current = HomeBox(
    "/assets/milkfish.jpg",
    "Milkfish Farming Assumptions",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable prawn and milkfish farming."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Milkfish Farming Assumptions</h2>
              {prawnAndMilkfishFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>Assumptions</strong>

              <br />
              <br />

              <table className="table is-fullwidth" style={{ width: "70%" }}>
                <tr>
                  <th>Item</th>
                  <th>Value</th>
                </tr>
                <TableRowItem
                  item="Milkfish growth rate"
                  value="2-3 g/per day"
                />
                <TableRowItem
                  item="Prawns growth rate"
                  value="0.36 to 0.58g/day"
                />
                <TableRowItem item="Prawns survival rate" value="76.95%" />
                <TableRowItem item="Milkfish survival rate" value="70%" />
                <TableRowItem
                  item="Cost of manual labour (man-day of 8 hours) KES"
                  value="500"
                />
                <TableRowItem
                  item="Size of Earthen pond"
                  value="600m2 20m*30m"
                />
                <TableRowItem
                  item="Earthen pond durability in years"
                  value="10"
                />
                <TableRowItem
                  item="Number of production cycles per year"
                  value="2"
                />
                <TableRowItem item="Number of elbows required" value="1" />
                <TableRowItem
                  item="Number of siening nets required"
                  value="1"
                />
              </table>
              <br />

              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeaweedAssumptions;

import React, { useState } from "react";

function RowItem(
  item,
  calculateTotalGrossReceipts,
  calculateTotalVariableCosts,
  totalNumCycles
) {
  const [costPerItem, setUnitCost] = useState(item.unitCost);
  const [quantity, setQuantity] = useState(item.qty);

  let itemTotal = item.unitCost * item.qty;

  if (totalNumCycles && totalNumCycles > 0) {
    itemTotal = itemTotal / totalNumCycles;
  }
  const [total, setTotal] = useState(itemTotal);

  const handleUnitCostChange = (event) => {
    let cost = event.target.value;
    let total = cost * quantity;

    if (totalNumCycles && totalNumCycles > 0) {
      total = total / totalNumCycles;
    }

    item.total = total;
    setUnitCost(cost);
    setTotal(total);
    calculateTotalGrossReceipts();
    calculateTotalVariableCosts();
  };

  const handleQtyChange = (event) => {
    let newQty = event.target.value;
    let total = newQty * costPerItem;

    if (totalNumCycles && totalNumCycles > 0) {
      total = total / totalNumCycles;
    }

    setQuantity(newQty);
    setTotal(total);
    item.total = total;
    calculateTotalGrossReceipts();
    calculateTotalVariableCosts();
  };

  const formattedNumber = total.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <div className="columns" style={{ marginBottom: "-3px" }}>
        <div className="column is-4 has-text-right0">{item.name}</div>
        <div className="column is-2">
          <input
            className="input"
            type="text"
            value={costPerItem}
            onChange={handleUnitCostChange}
            style={{ height: "75%" }}
          />
        </div>
        <div className="column is-2">
          <input
            className="input"
            type="text"
            value={quantity}
            onChange={handleQtyChange}
            style={{ height: "75%" }}
          />
        </div>
        <div className="column is-2 has-text-right">
          <input
            className="input"
            type="text"
            placeholder={formattedNumber}
            value={formattedNumber}
            style={{ textAlign: "right", height: "75%" }}
            disabled
          />
        </div>
      </div>
    </>
  );
}

export default RowItem;

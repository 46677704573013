import React, { useEffect, useRef } from "react";
import WebNavbar from "../components/web_navbar";

const QRCode = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container" style={{ marginLeft: "10%" }}>
            <h1 className="title">
              AgriProfit: Empowering Decision-Making in Agriculture Ventures
            </h1>
            <h2 className="subtitle">
              Your Comprehensive Solution for Accurate Financial Projections and
              Informed Agribusiness Choices.
            </h2>
          </div>
        </div>
      </section>

      <section className="section">
        <div class="columns is-centered">
          <div class="column is-half">
            <img src="/assets/site-qr-code.svg" />

            <br />
          </div>
        </div>

        <p>
          Powered by{" "}
          <a
            href="https://www.strutstechnology.co.ke"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#f7060c" }}
          >
            Struts Technology
          </a>
        </p>
      </section>
    </>
  );
};

export default QRCode;

import { createChatBotMessage } from "react-chatbot-kit";

const botName = "StrutsAI Chatbot";

const config = {
  // initialMessages: [createChatBotMessage(`Hello world`)],

  // Defines the chatbot name
  botName: botName,
  // Defines an array of initial messages that will be displayed on first render
  initialMessages: [
    createChatBotMessage(`Hi I'm ${botName}`),
    createChatBotMessage("How can I help you today?", {
      widget: "airportSelector",
      delay: 500,
    }),
  ],
  // Defines an object that will be injected into the chatbot state, you can use this state in widgets for example
  state: {
    airports: [],
    flightType: "",
    selectedFlightId: "",
    selectedFlight: null,
  },

  // Defines an object of custom components that will replace the stock chatbot components.
  customComponents: {
    // Replaces the default header
    // header: () => (
    //   <div
    //     style={{ backgroundColor: "red", padding: "5px", borderRadius: "3px" }}
    //   >
    //     This is the header
    //   </div>
    // ),
    // header: () => (
    //   <div
    //     style={{ backgroundColor: "red", padding: "5px", borderRadius: "3px" }}
    //   >
    //     This is the header <button onClick={close}>Close</button>
    //   </div>
    // ),
    // Replaces the default bot avatar
    // botAvatar: (props) => <FlightBotAvatar {...props} />,
    // Replaces the default bot chat message container
    // botChatMessage: (props) => <CustomChatMessage {...props} />,
    // Replaces the default user icon
    // userAvatar: (props) => <MyUserAvatar {...props} />,
    // Replaces the default user chat message
    // userChatMessage: (props) => <MyUserChatMessage {...props} />,
  },

  // Defines an object of custom styles if you want to override styles
  customStyles: {
    // Overrides the chatbot message styles
    botMessageBox: {
      backgroundColor: "#376B7E",
    },
    // Overrides the chat button styles
    chatButton: {
      backgroundColor: "#f7060c",
    },

    userMessageBox: {
      backgroundColor: "#0084ff",
      marginBottom: "20px",
      marginLeft: "20px",
      marginRight: "20px",
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
      borderBottomLeftRadius: "10px",
    },

    chatContainer: {
      bottom: "20px", // Adjust this value to control the vertical position from the bottom
      right: "20px", // Adjust this value to control the horizontal position from the right
      position: "fixed",
      zIndex: "999",
    },
  },
};

export default config;

import React, { useEffect, useRef } from "react";
import WebNavbar from "../components/web_navbar";
import HomeBox from "../components/HomeBox";
import { Link } from "react-router-dom";

const Home = () => {
  let aiBox = useRef();
  let seaweedFarmingBox = useRef();
  let mudCrabFarmingBox = useRef();
  let prawnFarmingBox = useRef();
  let milkfishFarmingBox = useRef();
  let prawnAndMilkfishFarmingBox = useRef();
  let tilapiaFarmingBox = useRef();

  aiBox.current = HomeBox(
    "/assets/ai.jpg",
    "AI Chatbot",
    "AI Chatbot trained on Agriprofit. Empowering Decision-Making in Agriculture Ventures. Your Comprehensive Solution for Accurate Financial Projections and Informed Agribusiness Choices."
  );

  seaweedFarmingBox.current = HomeBox(
    "/assets/seaweed.jpg",
    "Seaweed Farming",
    "Seaweeds are macro algae, generally occurring in marine habitats."
  );

  mudCrabFarmingBox.current = HomeBox(
    "/assets/mudcrab.jpg",
    "Mud Crab Farming",
    "Mud crabs belong to the family Portunidae and the genus Scylla. "
  );

  prawnFarmingBox.current = HomeBox(
    "/assets/prawns.jpg",
    "Prawn Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable prawn farming."
  );

  milkfishFarmingBox.current = HomeBox(
    "/assets/milkfish.jpg",
    "Milkfish Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable milkfish farming."
  );

  prawnAndMilkfishFarmingBox.current = HomeBox(
    "/assets/prawn-milkfish.jpg",
    "Prawn & Milkfish Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable prawn and milkfish farming."
  );

  tilapiaFarmingBox.current = HomeBox(
    "/assets/tilapia.jpg",
    "Tilapia Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable tilapia farming."
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container" style={{ marginLeft: "10%" }}>
            <h1 className="title">
              AgriProfit: Empowering Decision-Making in Agriculture Ventures
            </h1>
            <h2 className="subtitle">
              Your Comprehensive Solution for Accurate Financial Projections and
              Informed Agribusiness Choices.
            </h2>
          </div>
        </div>
      </section>

      {/* <section className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-half">
                <h1 className="title">Transforming the Mariculture Industry</h1>
                <h2 className="subtitle">
                  A user-friendly mobile application and training sessions for
                  farmers, entrepreneurs, researchers, and investors interested
                  in mariculture.
                </h2>
                <a className="button is-light">Get Started</a>
              </div>
              <div className="column is-half">
                <figure className="image is-4by3">
                  <img src="/assets/milkfish.jpg" alt="Mariculture Image" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="section">
        <div className="container">
          <section className="section">
            {/* <h1 className="title">
              AgriProfit: Empowering Decision-Making in Agriculture Ventures
            </h1>
            <h2 className="subtitle">
              Your Comprehensive Solution for Accurate Financial Projections and
              Informed Agribusiness Choices.
            </h2> */}

            <div className="columns">
              <div className="column">
                <Link to={{ pathname: `/seaweed` }}>
                  {seaweedFarmingBox.current}
                </Link>
              </div>
              <div className="column">
                <Link to={{ pathname: `/mud-crab` }}>
                  {mudCrabFarmingBox.current}
                </Link>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <Link to={{ pathname: `/prawn` }}>
                  {prawnFarmingBox.current}
                </Link>
              </div>
              <div className="column">
                <Link to={{ pathname: `/milkfish` }}>
                  {milkfishFarmingBox.current}
                </Link>
              </div>
            </div>

            <div className="columns">
              <div className="column">
                <Link to={{ pathname: `/tilapia` }}>
                  {tilapiaFarmingBox.current}
                </Link>
              </div>
              <div className="column">
                <Link to={{ pathname: `/agriprofit-ai` }}>{aiBox.current}</Link>
              </div>
            </div>

            <br />

            <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#f7060c" }}
              >
                Struts Technology
              </a>
            </p>
          </section>
        </div>
      </section>
    </>
  );
};

export default Home;

import React, { useEffect, useRef, useState } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import RowItemHeader from "../utils/RowItemHeader";
import RowItem from "../utils/RowItem";
import items from "./30-cage-items.json";

const ThirtyCage = () => {
  const [totalGrossReceipts, setTotalGrossReceipts] = useState(0.0);
  const [totalVariableCosts, setTotalVariableCosts] = useState(0.0);
  const [totalCosts, setTotalCosts] = useState(0.0);
  const [totalDepreciationCosts, setTotalDepreciationCosts] = useState(0.0);

  let mudCrabFarmingBox = useRef();

  mudCrabFarmingBox.current = HomeBox(
    "/assets/mudcrab.jpg",
    "Mud Crab Farming",
    "Mud crabs belong to the family Portunidae and the genus Scylla. There are four species of mud crabs i.e. Scylla serrata, Scylla tranquebarica, Scylla olivacea and Scylla paramamosain."
  );

  const grossReceipts = [];
  const variableCosts = [];
  const depreciationCosts = [];

  items.forEach((item, index) => {
    let itemTotal = item.unitCost * item.qty;
    item.total = itemTotal;

    if (item.category === "gross_receipts") {
      grossReceipts.push(item);
    }

    if (item.category === "variable_costs") {
      variableCosts.push(item);
    }

    if (item.category === "depreciation") {
      depreciationCosts.push(item);
    }
  });

  useEffect(() => {
    // window.scrollTo(0, 0);

    calculateTotalGrossReceipts();
    calculateTotalVariableCosts();
    calculateTotalDepreciationCosts();
  }, []);

  function calculateTotalGrossReceipts() {
    let total = 0.0;
    grossReceipts.forEach((item, index) => {
      total += item.total;
    });

    const formattedNumber = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalGrossReceipts(formattedNumber);
    console.log(totalGrossReceipts);
    console.log(totalDepreciationCosts);
  }

  function calculateTotalVariableCosts() {
    let total = 0.0;
    variableCosts.forEach((item, index) => {
      total += item.total;
    });

    setTotalVariableCosts(total);

    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);

    let totalCosts = totalDepreciationCosts + total;

    const formattedNumber = totalCosts.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalCosts(formattedNumber);
  }

  function calculateTotalDepreciationCosts() {
    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);

    let totalCosts = totalDepreciationCosts + totalVariableCosts;

    const formattedNumber = totalCosts.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalCosts(formattedNumber);
  }

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Mud Crab Farming</h2>
              {mudCrabFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>
                Business Model Costs Calculator
              </strong>
              <p className="">
                Update the open fields. Comprehensive financial statements, and
                reports will be generated automatically. These statements offer
                a comprehensive perspective on the financial well-being of the
                project.
                <br />
                <br />
                <div
                  className="buttons"
                  style={{ marginTop: "2px", marginBottom: "25px" }}
                >
                  <a
                    href="https://docs.google.com/spreadsheets/d/15AkXvDZzUO1tO7MGoJPCi0o8hjZ38Am7/edit#gid=202421082"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                  >
                    <strong>Google Excel Sheet</strong>
                  </a>
                  &nbsp;
                  <a
                    href="/assets/Crab-Fattening-Enterprise-budget.xlsx"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                    download
                  >
                    <strong>Download Excel</strong>
                  </a>
                </div>
                <b>Enterprise Budget for 30-Cage Mud Crab Cage Farm</b>
                <hr style={{ borderTop: "1px solid #bdbdbd" }} />
                <br />
                <RowItemHeader
                  item1="Item"
                  item2="Unit Cost (KES)"
                  item3="Quantity"
                  item4="Total Cost (KES)"
                />
                <RowItemHeader
                  item1="Gross Receipts"
                  item2=""
                  item3=""
                  item4=""
                />
                {grossReceipts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Variable Costs"
                  item2=""
                  item3=""
                  item4=""
                />
                {variableCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Variable Costs"
                  item2=""
                  item3=""
                  item4={totalVariableCosts.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
                <RowItemHeader
                  item1="Fixed Costs (non-cash expenses)"
                  item2=""
                  item3=""
                  item4=""
                />
                {depreciationCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Fixed Costs"
                  item2=""
                  item3=""
                  item4="5,000.00"
                />
                <RowItemHeader
                  item1="Total Costs"
                  item2=""
                  item3=""
                  item4={totalCosts}
                />
                <RowItemHeader
                  item1="Net income per cycle of 4 months"
                  item2=""
                  item3=""
                  item4="35,775.00"
                />
                <RowItemHeader
                  item1="Net income per year - 3 crops"
                  item2=""
                  item3=""
                  item4="107,325.00"
                />
                <RowItemHeader
                  item1="Break-even price per kg (KES)"
                  item2=""
                  item3=""
                  item4="533.00"
                />
                <RowItemHeader
                  item1="Break-even quantity of crabs (Kg)"
                  item2=""
                  item3=""
                  item4="62.00"
                />
                <RowItemHeader
                  item1="Break-even number of cages"
                  item2=""
                  item3=""
                  item4="19.00"
                />
              </p>
              <br />

              <br />
              <br />
              {/* <img src="/assets/dashboard.png" alt="Dashboard" /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThirtyCage;

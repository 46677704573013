import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const WebNavabar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    let accessToken = localStorage.getItem("esd_access_token");

    if (accessToken) {
      setIsLoggedIn(true);
    }

    window.scrollTo(0, 0);
  }, []);

  let homeDashboardLink = isLoggedIn ? (
    <Link to="/dashboard" className="navbar-item">
      Dashboard
    </Link>
  ) : (
    <Link to="/" className="navbar-item">
      Home
    </Link>
  );

  return (
    <nav
      className="navbar is-light"
      role="navigation"
      aria-label="main navigation"
      style={{ backgroundColor: "#ffffff" }}
    >
      <div className="container" style={{ backgroundColor: "#ffffff" }}>
        <div className="navbar-brand" style={{ marginLeft: "3%" }}>
          <Link className="navbar-item" to="/">
            <img
              src="/assets/doc-header.jpg"
              alt="GIZ"
              style={{
                maxHeight: "3.5rem",
                scale: "120%",
                width: "320px",
                // height: "120px",
              }}
            />
            &nbsp;&nbsp;&nbsp;&nbsp;
          </Link>

          <Link
            to="/"
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </Link>
        </div>

        <div
          id="navbarBasicExample"
          className="navbar-menu"
          style={{ backgroundColor: "#ffffff" }}
        >
          <div className="navbar-start" style={{ marginLeft: "15%" }}>
            {homeDashboardLink}

            <Link to="/documentation" className="navbar-item">
              Getting Started
            </Link>

            {/* <Link to="/documentation" className="navbar-item">
              Assumptions
            </Link>

            <Link to="/documentation" className="navbar-item">
              Summary Dashboard
            </Link>

            <Link to="/documentation" className="navbar-item">
              Financial Statements
            </Link> */}

            {/* <div className="navbar-item has-dropdown is-hoverable">
              <Link to="/" className="navbar-link">
                More
              </Link>

              <div className="navbar-dropdown">
                <Link to="/about" className="navbar-item">
                  About
                </Link>
                <Link to="/contact" className="navbar-item">
                  Contact
                </Link>
                <hr className="navbar-divider" />
                <Link to="/" className="navbar-item">
                  Report an issue
                </Link>
              </div>
            </div> */}
          </div>
          <div className="navbar-end">
            {/* <div className="navbar-item">
              <div className="buttons">{loginDashboardbutton}</div>
            </div> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default WebNavabar;

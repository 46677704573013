import React, { useEffect } from "react";
import WebNavbar from "../components/web_navbar";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <section className="section">
            <h1 className="title">
              Automated Fiscal Documents Processing with Kodi
            </h1>
            <h2 className="subtitle">
              The Kodi custom ESD system is able to process invoices and receipt
              documents automatically and post them to TRA.
            </h2>

            <br />

            <h1 className="title">Struts AI</h1>
            <h2 className="subtitle">
              Unleash the Power of AI to Revolutionize Your Business.
            </h2>

            <div>
              <div className="buttons">
                <a
                  href="https://ai.strutstechnology.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button is-primary"
                >
                  <strong>Get Started</strong>
                </a>
              </div>
            </div>

            <br />

            <p>
              Powered by{" "}
              <a
                href="https://www.strutstechnology.co.ke"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#f7060c" }}
              >
                Struts Technology
              </a>
            </p>
          </section>
        </div>
      </section>
    </>
  );
};

export default About;

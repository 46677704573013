import React, { useEffect, useRef } from "react";
import WebNavbar from "../components/web_navbar";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "../configs/chatbotConfig";
import AIMessageParser from "../chatbot/AIMessageParser";
import AIActionProvider from "../chatbot/AIActionProvider.js";
import { createClientMessage } from "react-chatbot-kit";

const AgriprofitAI = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    createClientMessage("Hello there.");
  }, []);

  return (
    <>
      <WebNavbar />
      <section className="hero is-medium is-primary is-bold">
        <div className="hero-body">
          <div className="container" style={{ marginLeft: "10%" }}>
            <h1 className="title">
              AgriProfit: Empowering Decision-Making in Agriculture Ventures
            </h1>
            <h2 className="subtitle">
              Your Comprehensive Solution for Accurate Financial Projections and
              Informed Agribusiness Choices.
            </h2>
          </div>
        </div>
      </section>

      <section className="section">
        <div class="columns is-centered" style={{ marginLeft: "10%" }}>
          <div class="column is-half">
            <img src="/assets/ai.jpg" />
          </div>
          <div class="column is-half">
            <div
              style={{
                border: "2px solid #00c09b",
                borderRadius: "7.5px",
                width: "max-content",
                marginTop: "5px",
              }}
            >
              <Chatbot
                config={config}
                messageParser={AIMessageParser}
                actionProvider={AIActionProvider}
              />
            </div>

            <br />
            <br />
            <br />
          </div>
        </div>

        <p>
          Powered by{" "}
          <a
            href="https://www.strutstechnology.co.ke"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#f7060c" }}
          >
            Struts Technology
          </a>
        </p>
      </section>
    </>
  );
};

export default AgriprofitAI;

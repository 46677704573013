import React, { useEffect, useRef, useState } from "react";
import HomeBox from "../../components/HomeBox";
import WebNavbar from "../../components/web_navbar";
import DocumentationSideMenu from "../../components/documentation_side_menu";
import RowItemHeader from "../utils/RowItemHeader";
import RowItem from "../utils/RowItem";
import items from "./items.json";

const TilapiaHome = () => {
  const [totalGrossReceipts, setTotalGrossReceipts] = useState(0.0);
  const [totalVariableCosts, setTotalVariableCosts] = useState(0.0);
  const [totalCosts, setTotalCosts] = useState(0.0);
  const [totalDepreciationCosts, setTotalDepreciationCosts] = useState(0.0);

  let tilapiaFarmingBox = useRef();

  tilapiaFarmingBox.current = HomeBox(
    "/assets/tilapia.jpg",
    "Tilapia Farming",
    "This training will provide the trainees with the necessary information for conducting profitable, gender inclusive and environmentally sustainable tilapia farming."
  );

  const grossReceipts = [];
  const variableCosts = [];
  const depreciationCosts = [];

  items.forEach((item, index) => {
    let itemTotal = item.unitCost * item.qty;
    item.total = itemTotal;

    if (item.category === "gross_receipts") {
      grossReceipts.push(item);
    }

    if (item.category === "variable_costs") {
      variableCosts.push(item);
    }

    if (item.category === "depreciation") {
      depreciationCosts.push(item);
    }
  });

  useEffect(() => {
    // window.scrollTo(0, 0);

    calculateTotalGrossReceipts();
    calculateTotalVariableCosts();
    calculateTotalDepreciationCosts();
  }, []);

  function calculateTotalGrossReceipts() {
    let total = 0.0;
    grossReceipts.forEach((item, index) => {
      total += item.total;
    });

    const formattedNumber = total.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalGrossReceipts(formattedNumber);
    console.log(totalGrossReceipts);
    console.log(totalDepreciationCosts);
  }

  function calculateTotalVariableCosts() {
    let total = 0.0;
    variableCosts.forEach((item, index) => {
      total += item.total;
    });

    setTotalVariableCosts(total);

    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);

    let totalCosts = totalDepreciationCosts + total;

    const formattedNumber = totalCosts.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalCosts(formattedNumber);
  }

  function calculateTotalDepreciationCosts() {
    let totalVariableCosts = 0.0;
    variableCosts.forEach((item, index) => {
      totalVariableCosts += item.total;
    });

    const formattedGrossReceiptsNumber = totalVariableCosts.toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
    setTotalGrossReceipts(formattedGrossReceiptsNumber);

    let totalDepreciationCosts = 0.0;
    depreciationCosts.forEach((item, index) => {
      totalDepreciationCosts += item.total;
    });

    setTotalDepreciationCosts(totalDepreciationCosts);

    let totalCosts = totalVariableCosts + totalDepreciationCosts;

    const formattedNumber = totalCosts.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setTotalCosts(formattedNumber);
  }

  return (
    <>
      <WebNavbar />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <DocumentationSideMenu />
            </div>
            <div
              className="column is-three-quarters"
              style={{ marginBottom: "12px", marginTop: "-10px" }}
            >
              <h2 className="title">Tilapia Farming</h2>
              {tilapiaFarmingBox.current}
              <strong style={{ marginBottom: "5px" }}>
                Business Model Costs Calculator
              </strong>
              <p className="">
                Update the open fields. Comprehensive financial statements, and
                reports will be generated automatically. These statements offer
                a comprehensive perspective on the financial well-being of the
                project.
                <br />
                <br />
                <div
                  className="buttons"
                  style={{ marginTop: "2px", marginBottom: "25px" }}
                >
                  <a
                    href="https://docs.google.com/spreadsheets/d/1WU-dE0mVzJ_SGt_QUTQb8ZBlKGXq47Ob63dKlaRg_jc/edit#gid=639773435"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                  >
                    <strong>Google Excel Sheet</strong>
                  </a>
                  &nbsp;
                  <a
                    href="/assets/GIZ-Business-Model-Costs-Calculator.xlsx"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button is-primary"
                    download
                  >
                    <strong>Download Excel</strong>
                  </a>
                </div>
                <b>
                  {" "}
                  Green Water System - Enterprise Budget (3 ponds of 300m2 each)
                </b>{" "}
                <hr style={{ borderTop: "1px solid #bdbdbd" }} />
                <br />
                <RowItemHeader
                  item1="Item"
                  item2="Unit Cost (KES)"
                  item3="Quantity"
                  item4="Total Cost (KES)"
                />
                <RowItemHeader
                  item1="Gross Receipts"
                  item2=""
                  item3=""
                  item4=""
                />
                {grossReceipts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Variable Costs"
                  item2=""
                  item3=""
                  item4=""
                />
                {variableCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Variable Costs"
                  item2=""
                  item3=""
                  item4={totalVariableCosts.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
                <RowItemHeader
                  item1="Fixed Costs (non-cash expenses)"
                  item2=""
                  item3=""
                  item4=""
                />
                {depreciationCosts.map((item) =>
                  RowItem(
                    item,
                    calculateTotalGrossReceipts,
                    calculateTotalVariableCosts
                  )
                )}
                <RowItemHeader
                  item1="Total Fixed Costs"
                  item2=""
                  item3=""
                  item4={totalDepreciationCosts.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                />
                <RowItemHeader
                  item1="Total Costs"
                  item2=""
                  item3=""
                  item4={totalCosts}
                />
                <RowItemHeader
                  item1="Net Income per production cycle of 9 months"
                  item2=""
                  item3=""
                  item4="60,835.00"
                />
                <RowItemHeader
                  item1="Break-even price per piece"
                  item2=""
                  item3=""
                  item4="80"
                />
                <RowItemHeader
                  item1="Break-even pieces of 208g tilapia"
                  item2=""
                  item3=""
                  item4="1023"
                />
                <RowItemHeader
                  item1="Break-even pond area(m2)"
                  item2=""
                  item3=""
                  item4="602"
                />
              </p>
              <br />

              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TilapiaHome;

import React, { useState } from "react";
import { useGlobalState } from "../../GlobalStateContext";

function TableRowItem({ item, value, updateFunction }) {
  const [itemValue, setItemValue] = useState(value);
  const { state, dispatch } = useGlobalState();

  return (
    <tr>
      <td>{item}</td>
      <td style={{ textAlign: "center" }}>
        <input
          className="input"
          type="text"
          value={itemValue}
          onChange={(e) => setItemValue(e.target.value)}
          style={{ height: "75%" }}
        />
      </td>
    </tr>
  );
}

export default TableRowItem;
